import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog"]

  close = () => {
    const dialog = this.dialogTarget
    dialog.close();
  };

  open = (e) => {
    e.preventDefault();
    document.body.classList.add("overflow-hidden");
    const dialog = this.dialogTarget
    dialog.showModal();
    dialog.classList.remove("backdrop:opacity-0", "opacity-0", "backdrop:bg-black/0")
    dialog.classList.add("backdrop:opacity-100", "opacity-100", "backdrop:bg-black/40")
  }

  connect() {
    const dialog = this.dialogTarget
    dialog.addEventListener("close", () => {
      document.body.classList.remove("overflow-hidden");
      dialog.classList.add("backdrop:opacity-0", "opacity-0", "backdrop:bg-black/0")
      dialog.classList.remove("backdrop:opacity-100", "opacity-100", "backdrop:bg-black/40")
    });
  }
}
