import {
	create,
	get,
	parseCreationOptionsFromJSON,
	parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import {
	PublicKeyCredentialCreationOptionsJSON,
	PublicKeyCredentialRequestOptionsJSON,
} from "@github/webauthn-json/dist/types/basic/json";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["publicKeyCredential"];
	static values = { options: Object };

	declare optionsValue: PublicKeyCredentialCreationOptionsJSON &
		PublicKeyCredentialRequestOptionsJSON;
	declare publicKeyCredentialTarget: HTMLInputElement;

	#callback = (event: Event, fnType: "create" | "get") => {
		if (
			this.publicKeyCredentialTarget.value.length > 0 ||
			!window.navigator.credentials
		) {
			return;
		}

		event.preventDefault();
		let promise;
		if (fnType === "create") {
			const options = parseCreationOptionsFromJSON({
				publicKey: this.optionsValue,
			});
			promise = create(options);
		}
		if (fnType === "get") {
			const options = parseRequestOptionsFromJSON({
				publicKey: this.optionsValue,
			});
			promise = get(options);
		}

		if (promise)
			promise
				.then((pk) => {
					this.publicKeyCredentialTarget.value = JSON.stringify(pk);
					(this.element as HTMLFormElement).requestSubmit();
				})
				.catch((e) => {
					alert(e.message);
				});
	};

	create = (e: Event) => {
		this.#callback(e, "create");
	};

	verify = (e: Event) => {
		this.#callback(e, "get");
	};
}
