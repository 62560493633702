import { Controller } from "@hotwired/stimulus"

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default class extends Controller {
  static values = {
    events: { type: Array, default: ["change", "input"] }
  }

  dragover = (e) => {
    e.currentTarget.classList.add("border-budgetal-400")
    e.preventDefault();
  }

  dragleave = (e) => {
    e.currentTarget.classList.remove("border-budgetal-400")
    e.preventDefault();
  }

  drop = (ev) => {
    ev.preventDefault();
    ev.currentTarget.querySelector("span").innerText = "Uploading..."

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const form = this.element.closest("form")
          const formData = new FormData(form);

          const file = item.getAsFile();
          formData.append("file", file);
          fetch(form.action, { body: formData, method: "POST" })
            .then(r => r.text())
            .then(body => {
              const main = /<main>(.*?)<\/main>/g.exec(body.replaceAll("\n", ""))
              document.querySelector("main").innerHTML = main[1]
            })
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        console.log(file.name, file);
        this.element.querySelector("input[type='file']").files = [file]
      });
    }
  }

  submit = debounce((e) => {
    if (e.target.dataset.submit === "false") { return }
    this.element.requestSubmit();
  }, 100)

  connect = () => {
    this.element.dataset.action = this.eventsValue.map(e => `${e}->submit-on-change#submit`).join(" ")
  }
}
