import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		const dialog = this.element?.querySelector("dialog");
		dialog?.removeAttribute("open");
		dialog?.classList.remove("hidden");
		dialog?.close();

		dialog?.addEventListener("close", function (event) {
			document.body.classList.remove("overflow-hidden");
		});
		dialog?.addEventListener("click", function (event) {
			const rect = dialog.getBoundingClientRect();
			const isInDialog =
				rect.top <= event.clientY &&
				event.clientY <= rect.top + rect.height &&
				rect.left <= event.clientX &&
				event.clientX <= rect.left + rect.width;
			if (!isInDialog) {
				dialog.close();
			}
		});

		this.element
			?.querySelector(".add_expense")
			?.addEventListener("click", (e) => {
				document.body.classList.add("overflow-hidden");
				e.preventDefault();
				dialog?.showModal();
			});

		dialog
			?.querySelector("label[for='add_expense']")
			?.addEventListener("click", (e) => {
				e.preventDefault();
				dialog?.close();
			});
	}
}
