import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["title", "body", "button"];
	static values = {
		title: String,
		body: String,
		button: String,
		classes: String,
	};

	declare titleValue: string;
	declare bodyValue: string;
	declare buttonValue: string;
	declare classesValue: string;

	declare titleTarget: HTMLDivElement;
	declare bodyTarget: HTMLDivElement;
	declare buttonTarget: HTMLButtonElement;

	requestSubmit = (e) => {
		const button = e.currentTarget
		const text = button.innerText
		button.disabled = true
		button.innerText = "Loading..."
		setTimeout(() => {
			button.disabled = false
			button.innerText = text
		}, 2000)
		const form = this.element.querySelector("form")
		form?.requestSubmit();
	};

	close = () => {
		const dialog = this.element as HTMLDialogElement;
		dialog?.close();
	};

	connect() {
		const dialog = this.element as HTMLDialogElement;
		this.buttonTarget.innerText = this.buttonValue;
		if (this.classesValue.length > 0) {
			this.buttonTarget.setAttribute("class", "")
			this.buttonTarget.classList.add(...this.classesValue.split(" "));
			this.buttonTarget.classList.remove("rounded-md", "px-2");
			this.buttonTarget.classList.add("rounded-full", "px-4", "py-2", "font-semibold");
		}
		if (this.titleValue === "Are you sure?" && this.bodyValue === "Are you sure?") {
			this.titleValue = "Confirm"
		}
		this.titleTarget.innerText = this.titleValue;
		this.bodyTarget.innerText = this.bodyValue;

		document.body.classList.add("overflow-hidden");
		dialog.showModal();

		dialog.addEventListener("close", () => {
			document.body.classList.remove("overflow-hidden");
			dialog.remove()
		});

		setTimeout(() => {
			dialog.classList.remove("backdrop:opacity-0", "opacity-0", "backdrop:bg-black/0")
			dialog.classList.add("backdrop:opacity-100", "opacity-100", "backdrop:bg-black/40")
		}, 5)

		dialog.addEventListener("click", function (event) {
			const rect = dialog.getBoundingClientRect();
			const isInDialog =
				rect.top <= event.clientY &&
				event.clientY <= rect.top + rect.height &&
				rect.left <= event.clientX &&
				event.clientX <= rect.left + rect.width;
			if (!isInDialog) {
				// dialog.close();
			}
		});
	}
}
