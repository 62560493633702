import { Controller } from "@hotwired/stimulus";

function showUploadWindow(title?: string) {
	const template = document.querySelector<HTMLTemplateElement>(
		"template#upload-progress-template"
	);
	if (template) {
		const content: Node = template.content;
		const alt = document.importNode(content, true) as Element;
		const button = alt?.querySelector("button");
		if (button) {
			button.addEventListener("click", () => {
				hideUploadWindow();
			});
		}

		document.body.appendChild(alt);
		updateUploadTitle(title);
	}
}

function hideUploadWindow() {
	const modal = document.querySelector("#upload-progress-modal");
	if (modal) {
		modal.parentElement?.removeChild(modal);
	}
}

function updateUploadTitle(title?: string) {
	const alertTitle = document.querySelector("#alert-title") as HTMLElement;
	if (alertTitle && title) {
		alertTitle.innerText = title;
	}
}

function updateUploadProgress(progress = 0) {
	const progressMeter = document.querySelector("#progress-meter");
	progressMeter?.setAttribute("style", `width: ${progress}%`);

	const progressText = document.querySelector("#progress-text") as HTMLElement;
	if (progressText) {
		progressText.innerText = `${progress.toFixed()}% Complete`;
	}
}

export default class extends Controller {
	#start = () => {
		showUploadWindow("Uploading...");
	};

	#progress = (e: Event) => {
		// @ts-ignore
		const progress = e.detail.progress as number;
		updateUploadProgress(progress);
	};

	#end = () => {
		updateUploadTitle("Upload complete");
		setTimeout(hideUploadWindow, 100);
	};

	#error = () => {
		updateUploadTitle("Error uploading file");
	};

	disconnect() {
		document.removeEventListener("direct-uploads:start", this.#start);
		document.removeEventListener("direct-upload:progress", this.#progress);
		document.removeEventListener("direct-uploads:end", this.#end);
		document.removeEventListener("direct-upload:error", this.#error);
	}

	connect() {
		document.addEventListener("direct-uploads:start", this.#start);
		document.addEventListener("direct-upload:progress", this.#progress);
		document.addEventListener("direct-uploads:end", this.#end);
		document.addEventListener("direct-upload:error", this.#error);
	}
}
