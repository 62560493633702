import { Controller } from "@hotwired/stimulus"

export function loadScript(url: string, type = "text/javascript") {
  if (!url) return;

  const existingScript = document.querySelector("script[src*='" + url + "']");
  const head = document.querySelector("head");
  if (!existingScript && head) {
    const script = document.createElement("script");
    script.setAttribute("src", url);
    script.setAttribute("type", type);
    script.setAttribute("data-turbo-temporary", "true");
    head.appendChild(script);
  }
}

async function initChart() {
  return new Promise((resolve) => {
    if (window.ApexCharts) {
      resolve(undefined);
    } else {
      loadScript("https://cdn.jsdelivr.net/npm/apexcharts");
      let i = setInterval(() => {
        if (window.ApexCharts) {
          clearInterval(i);
          resolve(undefined);
        }
      }, 100);
    }
  });
}

function numberToCurrency(value: string | number) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

export default class extends Controller {
  static values = {
    title: String,
    height: Number,
    series: Array, // {name, data}
  };

  declare readonly titleValue: string
  declare readonly heightValue: string
  declare readonly seriesValue: {name: string}[]

  connect = () => {
    const ctx = this.element
    const title = this.titleValue
    const height = this.heightValue
    const series = this.seriesValue

    initChart().then(() => {
      if (!window.ApexCharts) {
        return
      }

      console.log('boom')
      let chart = new window.ApexCharts(ctx, {
        title: {
          text: title,
          align: 'left'
        },
        chart: {
          type: 'area',
          stacked: false,
          height: height,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          events: {
            markerClick: function(event:any, chartContext: any, { dataPointIndex }) {
              window.Turbo.visit(`${window.location.pathname}/${dataPointIndex+1}`)
            },
          },
        },

        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },

        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        stroke: {
          curve: 'smooth',
        },
        series: series,
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          labels: {
            formatter: function (val: string, loa: string) {
              return numberToCurrency(val)
            },
          },
          format: function (val: string) {
            return numberToCurrency(val)
          },
          title: {
            text: ''
          },
          opposite: true,
        }
      })

      chart.render();
    })
  }
}
