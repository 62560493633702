import { Controller } from "@hotwired/stimulus";

function updateCount() {
	const countNode = document.querySelector(
		"span.import_count"
	) as HTMLSpanElement | null;
	if (countNode) {
		const count = document.querySelectorAll("tr").length - 1;
		if (count === 1) {
			countNode.innerText = "1 expense";
		} else {
			countNode.innerText = `${count} expenses`;
		}
		if (count === 0) {
			window.location.reload();
		}
	}
}

export default class extends Controller {
	skip() {
		this.element.closest("tr")?.remove();
		updateCount();
	}

	#stopRedirect = (e: Event) => {
		// @ts-ignore
		const url = (e.detail?.url || "") as string;
		const isMatch = /\/budgets\/\d+/.test(url);
		if (isMatch) {
			e.preventDefault();
		}
	};

	#disableForm = (e: Event) => {
		if (e.target === this.element) {
			this.element.querySelectorAll("button").forEach((b) => {
				b.disabled = true;
			});
		}
	};

	#handleResponse = (e: Event) => {
		if (e.target === this.element) {
			// @ts-ignore
			if (e.detail.success) {
				window.toast({ type: "notice", text: "Imported Expense" });
				this.element.closest("tr")?.remove();
				updateCount();
			} else {
				window.toast({ type: "error", text: "Something went wrong" });
			}
		}
	};

	disconnect(): void {
		document.removeEventListener("turbo:before-visit", this.#stopRedirect);
		document.removeEventListener("turbo:submit-start", this.#disableForm);
		document.removeEventListener("turbo:submit-end", this.#handleResponse);
	}

	connect(): void {
		updateCount();
		document.addEventListener("turbo:before-visit", this.#stopRedirect);
		document.addEventListener("turbo:submit-start", this.#disableForm);
		document.addEventListener("turbo:submit-end", this.#handleResponse);
	}
}
