import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	submit() {
		(this.element as HTMLFormElement).requestSubmit();
	}

	connect() {
		this.element.querySelector("input[type=submit]")?.classList.add("hidden");
	}
}
